/* our custom styles */

// colors
@lmu-gray-bg: #CECEC2;
@lmu-gray-light: lighten(@lmu-gray-bg, 6%); // #DCDCD3
@lmu-gray-border: #AFAFAF;
@lmu-gray-discrete: #7E7E7E;


//*// DEVICES
@plone-screen-xs-min: 480px;
@plone-screen-sm-min: 768px;
@plone-screen-md-min: 955px;
@plone-screen-lg-min: 1200px;

@plone-container-sm: 750px;
@plone-container-md: 955px;
@plone-container-lg: 955px;

@plone-grid-columns: 12;
@plone-grid-gutter-width: 30px;
@plone-grid-float-breakpoint: @plone-screen-sm-min;

//*// CONTAINERS
@plone-container-tablet:             ((720px + @plone-grid-gutter-width));
@plone-container-sm:                 @plone-container-tablet;

@plone-container-desktop:            ((940px + @plone-grid-gutter-width));
@plone-container-md:                 @plone-container-desktop;

@plone-container-large-desktop:      ((940px + @plone-grid-gutter-width));
@plone-container-lg:                 @plone-container-large-desktop;

@plone-link-color: #006633;
@plone-portlet-footer-bg: @lmu-gray-bg;
@plone-footer-bg: #2e3133;
@plone-text-color: #000;
@plone-font-size-base: 12.8px;
@plone-line-height-base: 1em;
@plone-font-size-h1: 1.2em;
@plone-font-size-h2: 1em;
@plone-font-size-h3: 0.85em; // ~24px
@plone-font-size-h4: 0.8em; // ~18px
@plone-font-size-h5: 0.8em;
@plone-font-size-h6: 0.8em; // ~12px
@plone-portlet-border: 1px solid @lmu-gray-border;



h1, h2, h3 { font-weight: bold;}
#content-core h1, h2, h3, h4, h5, h6 { clear: both}

.outer-wrapper{
  max-width: 955px;
  margin: 0 auto;
}

body {
  background-color: #fff;
  font: @plone-font-size-base "Lucida Grande", Verdana, Lucida, Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

input[type="text"],
input[type="password"],
textarea, select{
  height: 2.6em;
}

.documentFirstHeading {
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 0;
  border-bottom: 0 transparent;
}

#viewlet-above-content{
  display: block;
  position: relative;
  margin-top: -0.3px;
  padding-top: 1.3em;
  border-top: 1px solid @lmu-gray-border;
}

// disable icon for external links
.glyphicon.link-external {
  display: none;
}

// breadcrumbs
@plone-breadcrumb-bg: transparent;
@plone-breadcrumb-padding-horizontal: 10px;
@plone-breadcrumb-padding-vertical: 8px;
.plone-breadcrumb {
  font-size: 0.8em;
  color: @lmu-gray-discrete;
  > .container { padding-left: 0; }
  ol{
    > li {
      + li:after {
        content: "";
        position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-bg;
        border-left: 10px solid;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        left: -16px;
        top: -5px;
        padding: 0 !important;
      }
      + li:before {
        content: "";
        position: absolute;
        padding: 0 5px;
        color: @plone-breadcrumb-color;
        border-left: 10px solid;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        left: -15px;
        top: -5px;
        padding: 0 !important;
      }

    }
  }
}


// reset navigation icons:
body#visual-portal-wrapper.pat-plone .outer-wrapper {
  [class*="contenttype-"]:before{
    content: none;
  }
}

// navigation portlet:
//*// Navigation
.portletNavigationTree nav.portletContent > ul > li a { padding: 2px 2px 5px 15px; border-top: 0}
.portletNavigationTree nav.portletContent > ul > li strong { padding: 2px 2px 5px 15px; border-top: 0; background: @plone-portlet-footer-bg; color: #000; display: block; font-weight: @plone-font-weight-regular;}
.portletNavigationTree {
  box-shadow: none;
  border: 0;
  .portletContent {
    border-top: 0;
    .navTreeItem {
      border-top: 0;
      .contenttype-easynewsletter{
        img {display: none}
      }
    }
    a {
      color: #000; text-decoration: none;
      &:hover {background: @lmu-gray-light !important;}
      &:before {content: none !important;}
    }
    > ul > li > a {
      display: block;
      background: transparent;
      &:before {content: none !important;}
      &:hover:after {content: none !important;}
      img {display: none}
    }
    > ul > li > ul a {padding-left:20px;}
    > ul ul {padding-left: 0;}
    .navTreeCurrentNode {
      > a {
        color: #000;
        background: @plone-portlet-footer-bg;
      }
      &:after {content: none !important;}
    }
    .navTreeLevel0 {
      > li {
        border-top: @plone-portlet-border;
        > a {
          text-transform: uppercase;
          padding-left: 0.2em;
          margin-top: 1px;
          margin-bottom: 1px;
        }
      }
    }
    .navTreeLevel1 > li > a {
      padding-left: 1.2em;
    }
  }
}
#sidebar{
  padding-left: 0;
}

// type icons
.icon-easynewsletter-actions::before {
  content: none !important;
}
body#visual-portal-wrapper.pat-plone .outer-wrapper {
  .contenttype-easynewsletter:before {
    content: none !important;
  }
  .contenttype-enlissue:before {
    content: none !important;
  }
  .contenttype-enlsubscriber:before {
    content: none !important;
  }
  .contenttype-enltemplate:before {
    content: none !important;
  }
}


#portal-footer {
  border-top: 1px solid @lmu-gray-border;
  text-align: center;
  font-size: 0.8em;
}

.portlet.portletActions{
  border-width: 0;
  margin-bottom: 0;
  box-shadow: none;
  .portletContent{
    border-top: 0 solid transparent;
    margin-bottom: 0.5em;
    > ul {
      text-align: center;
      > li {
        display: inline;
        margin-right: 1em;
        a{
          display: inline;
          padding: 0;
          position: static;
          z-index: 0;
          text-decoration: none;
          border-top: 0;
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

#content table{
  td{padding: 0.2em 0.5em;}
  thead{
    tr{
      font-weight: bold;
    }
  }
  tr{
    background: #DCDCD3;
    &.odd{
      background: #F4F4F4;
    }
    td{

    }
  }
}

.lector-details {
  .abstract{font-style: italic;margin-left:1em;}
}

.section-front-page{
  #portal-breadcrumbs,
  .documentFirstHeading,
  .documentDescription{
    display: none;
  }
  .frontpage-grid{
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-left: 0;
    border: 1px solid white;
    .row {
      display: table-row;
      height: 170px;
      > div {
        width: 25%;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        border: 1px solid white;
        &:hover{ background: lightgray !important; color: #333 !important;}
        a {
          font-size: 180%;
          color: #fff;
          text-decoration: none;
          display: block;
          width: 100%;
          height: auto;
          padding-top: 0.2em;
          padding-bottom: 0.2em;
          background: rgba(0,0,0,0.4);
        }
        &.no-bg a{
          background: transparent;
          color: #333;
          &:hover{ background: lightgray !important; color: #333 !important;}
        }
      }
    }
  }
}

.portaltype-seminar table {
  margin: 2em 0;
}


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
  padding: 0;
  margin: 0 0 @grid-gutter-width 0;
  list-style: none;
  .make-row();

  > li {
    .make-xs-column(12);
    .make-sm-column(6);
    margin-top: @grid-gutter-width;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

